.post {
	&__content {
		overflow: hidden;
		text-overflow: ellipsis;
		word-break: break-all;

		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 4;
		line-height: 1.5em;
	}
}
