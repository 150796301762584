.test-fade-out {
	animation: fadeOut 0.5s;
	animation-fill-mode: forwards;
}

.test-fade-in {
	animation: fadeIn 0.5s;
	animation-fill-mode: forwards;
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@keyframes fadeOut {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}
