@tailwind base;
@tailwind components;
@tailwind utilities;

@import './page/notfound.scss';
@import './components/postitem.scss';
@import './components/loginpage.scss';
@import './page/detail.scss';
@import './tailwind_component.scss';
@import './components/questionpage.scss';
@import './detailscoremenu.scss';
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic:wght@400;700;800&display=swap');

#root {
	min-height: calc(100vh - (100vh - 100%));
}

html,
body {
	// height: 100%;
}

body {
	font-family: 'Nanum Gothic', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

* {
	box-sizing: border-box;
}
