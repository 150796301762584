.login {
	margin: 0 auto;
	&__label {
		@apply font-bold text-lg;
		// text-white
	}
	&__form {
		@apply w-full rounded-md border border-slate-300;
	}
	&__valid-text {
		@apply text-red-500 font-bold mt-2;
	}
	&__btn {
		@apply w-full rounded-md bg-main-blue text-white font-bold;
	}
}
