.table {
	margin-bottom: 30px;
	border-collapse: separate;
	border-spacing: 0;
	thead {
		th {
			padding: 20px;
		}
	}
	tbody {
		td {
			border-bottom: 1px solid rgb(229, 231, 235);
		}
	}
}
