.background {
	@apply fixed z-20 top-0 left-0 w-full h-full bg-black/80;
	@apply h-full overflow-hidden;
}

.data-background {
	@apply fixed z-20 top-0 left-0 w-full h-full bg-black/60;
	@apply h-full overflow-hidden;
}

.modal {
	&__question {
		@apply absolute w-[700px] h-[700px] bg-white z-20;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	&__upload {
		@apply absolute w-[80%] h-full bg-white z-20;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		overflow: hidden;

		&__span {
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 1;
			overflow: hidden;
			text-overflow: ellipsis;
			line-height: 1.5em;
			max-height: 1.5em;
		}
	}
	&__chart {
		@apply absolute w-[80%] h-full bg-card-theme z-20;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	&__data {
		@apply absolute w-[890px] h-[600px] bg-card-theme z-20;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		.first {
			@apply w-full border;
			th,
			td {
				@apply border border-slate-600;
			}
			td {
				@apply text-center;
			}
		}
		.second {
			@apply w-full border;
			th,
			td {
				@apply border border-slate-600;
				span {
					@apply ml-3;
				}
			}
		}
	}
}

.field-set {
	ul {
		li {
			display: flex;
			align-items: center;
			label {
				width: 100px;
				padding: 5px;
				margin: 10px 0px;
			}
			input {
				display: inline-block;
				width: 100%;
				padding: 6px;
			}

			select {
				width: 100%;
				padding: 5px;
			}
		}
	}
}
