@layer components {
	.responsive-screen {
		@apply w-[88vw] mx-auto;
		// @apply sm:max-w-lg md:max-w-2xl lg:max-w-4xl xl:max-w-5xl 2xl:max-w-7xl;;;
	}

	.responsive-grid {
		@apply grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4;
	}

	.responsive-header-text {
		@apply text-[10px] sm:text-[16px] md:text-lg lg:text-xl;
	}

	// 마지막에 arbitary를 못쓰게 되어 있다. 버그인듯.
	.responsive-detail-header-text {
		@apply text-[8px] sm:text-[10px] md:text-[11px] lg:text-xs;
	}

	// navigation
	.flex-center {
		@apply flex justify-center items-center;
	}
	.chart-width {
		@apply w-[80vw] mx-auto;
	}
}
